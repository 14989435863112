import AnimateWhenVisible from './components/layout/AnimateWhenVisible';
import Carousel from "./components/layout/Carousel";
import CollapsibleFAQ from "./components/layout/CollapsibleFAQ";
import DeferredBlock from "./components/layout/DeferredBlock";
import MobileScroller from "./components/layout/MobileScroller";
import Parallax from "./components/layout/Parallax";
import Slideshow from "./components/layout/Slideshow";
import Sticky from "./components/layout/Sticky";
import SubNavigation from "./components/layout/SubNavigation";
import Lightbox from "./components/content/Lightbox";
import { DuviriHubsite } from "./components/content/duviri/index.js";
import { PowerPacks } from "./components/content/powerpacks/index.js";
import { PrimeResurgence } from "./components/content/primeresurgence/index.js";
import { Tennocon2024 } from "./components/content/tennocon2024/index.js";

const matchMobileOnly = window.matchMedia("(max-width: 767px)");
const matchTabletOnly = window.matchMedia("(min-width: 768px) and (max-width: 1279)");
const matchTabletUp = window.matchMedia("(min-width: 768px)");
const matchDesktopOnly = window.matchMedia("(min-width: 1280px) and (max-width: 1919px)");
const matchDesktopUp = window.matchMedia("(min-width: 1280px)");
const matchWideUp = window.matchMedia("(min-width: 1920px)");

let PageComponents = window.PageComponents = {
    beforeLoadCallbacks: [],
    afterLoadCallbacks: [],
    isIOS: /iPad|iPhone|iPod/.test(navigator.userAgent),
    isAndroid: /Android/.test(navigator.userAgent),
    isMobileOnly: () => matchMobileOnly.matches,
    isTabletOnly: () => matchTabletOnly.matches,
    isTabletUp: () => matchTabletUp.matches,
    isDesktopOnly: () => matchDesktopOnly.matches,
    isDesktopUp: () => matchDesktopUp.matches,
    isWideUp: () => matchWideUp.matches,
};

PageComponents.beforeLoad = (callback) => {
    PageComponents.beforeLoadCallbacks.push(callback);
}
PageComponents.afterLoad = (callback) => {
    PageComponents.afterLoadCallbacks.push(callback);
}

// Helper for iterating nodelists, which do not have a natural forEach
window.forEachNode = (nodes, callback) => {
    for (let i = 0; i < nodes.length; ++i) {
        callback(nodes[i], i);
    }
}

// Page load lifecycle handler
window.addEventListener('load', () => {

    // Pre-initialization callbacks
    PageComponents.beforeLoadCallbacks.forEach((callback) => {
        callback(window.PageComponents);
    });

    // Initialize components with behavior
    Parallax.init(PageComponents);
    Carousel.init(PageComponents);
    DeferredBlock.init(PageComponents);
    MobileScroller.init(PageComponents);
    SubNavigation.init(PageComponents);
    Slideshow.init(PageComponents);
    Sticky.init(PageComponents);
    CollapsibleFAQ.init(PageComponents);
    AnimateWhenVisible.init(PageComponents);
    Lightbox.init(PageComponents);

    if ( document.body.classList.contains( "duviri_paradox" ) ) {
        DuviriHubsite.init( PageComponents );
    }

     if ( document.getElementById( "power-packs" ) ) {
         PowerPacks.init( PageComponents );
     }

    if ( document.getElementById( "prime_resurgence" ) ) {
        PrimeResurgence.init( PageComponents );
    }

    if ( document.body.classList.contains( "tennocon_main" ) || document.body.classList.contains( "tennocon_tickets" ) ) {
        Tennocon2024.init( PageComponents );
    }

    // Post-initialization callbacks
    PageComponents.afterLoadCallbacks.forEach((callback) => {
        callback(PageComponents);
    });

});
